import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Cards from "./pages/Cards";
import Home from "./pages/Home";
import MyProfile from "./pages/MyProfile";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/cards" element={<Cards />} />
      <Route path="/profile" element={<MyProfile />} />
    </Routes>
  );
}

export default App;
